var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-news"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("岳麓山国家大学科技城：23平方公里的产学研高地")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"pagination"},[_c('div',{staticClass:"a-more"},[_c('span',{on:{"click":function($event){return _vm.$router.push('/indexOs/newsDetails8')}}},[_vm._v("上一条：【壮丽70年·奋斗新时代】星城闪耀“未来之光")]),_c('span',{on:{"click":function($event){return _vm.$router.push('/indexOs/newsDetails10')}}},[_vm._v("下一条：两山”崛起“头脑产业” —长沙高质量发展调研行")])]),_c('el-button',{attrs:{"plain":""},on:{"click":function($event){return _vm.backGo()}}},[_vm._v(" 返回 ")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-setting"},[_c('h3',{staticClass:"time"},[_vm._v(" 发布时间： 2019-05-20 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"40px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news9/news1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"20px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news9/news2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"20px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news9/news3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"20px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news9/news4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin":"20px 0 40px"}},[_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 承续岳麓千年文脉的岳麓山国家大学科技城有20多所高校院所集聚，这片没有围墙的大学城正在成为自主创新策源地，科技成果转化地和高端人才聚集地。 ")]),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 湘江之滨，麓山脚下，长约5.3公里的麓山南路，自北向南依次贯穿湖南师范大学，湖南大学和中南大学校区。岳麓山国家大学科技城就在这里延展。集聚32位两院院士，57个国家和省部级重点实验室，10多万名科研人员。 ")]),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 在这张智慧课桌上，学生提交试卷的同一时间，老师就能得知每名学生的答题情况。 ")]),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 湖南师大附中博才实验中学老师 吴静文：一秒实现我能找到他的问题在哪里，有哪些是掌握的比较好的，有哪些不好的，通过评测的结果一目了然。 ")]),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 智慧课堂是基于国家自然科学奖一等奖“透明计算”技术的一项实际应用成果。2015年，中南大学张尧学院士团队与新云网合作开展产学研攻关，研发的云智教已在多所学校推广应用，云慧医也在全国十几家三甲医院使用。 ")])])
}]

export { render, staticRenderFns }